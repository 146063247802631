// 繁体中文

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "我要貸款",
        navTitlePlaceholder: "最快15秒內出結果",
        applicationProcedure: "申請流程",
        customerInform: "授權店鋪信息",
        investigate: "即時審查",
        submitDocument: "店鋪平台簽署協議",
        transfer: "打款至店鋪",
        withdraw: "餘額增加",
        attention: "* 注：請務必確認貸款條件後申請",
        supportingDocuments: "可用的證明性文件",
        notificationTimeNav: "審核結果通知",
        timeItem1: "平日",
        timeItem1Title: "週一與週日全天",
        timeItem2: "每月第三個週日",
        timeItem2Title: "00:00至00:20系統維護",
        timeNotification1: "審核結果將發送至店鋪登錄郵箱，請及時查收。",
        timeNotification2: "※審批金額會直接打款至您的店鋪。",
        timeNotification3: "審批金額根據店鋪緯度生成，可能會不符合您的期望。",
        bMConditionsNav: "貸款條件", // 貸款條件標題
        bMCContentNav: "貸款條件",
        bMCContent1: "年齡70歲以下且有穩定收入(部分有收入也可以)。",
        bMCContent2: "在交易期間滿71歲時,我們將停止貸款。",
        bMCHintNav: "注",
        bMCHint1: "請仔細查閱借款條件。",
        bMCHint2: "本公司簽訂的貸款業務，由貸款業協會諮詢糾紛解決中心處理。",
        bMCHint3: "上文提及有關規定。",
        selectMethodsNav: "如何0利息貸款",
        sMInformNav: "條件",
        sMInform1: '店鋪運營時間達到3年!',
        sMInform2: "店鋪3年內無違約行為。",
        sMInform3: '店鋪平台內無超過3日採購。 ',
        EnhancedNav: "安全操作",
        ESInformNav: "注意惡意手法",
        ESInform1: "※打款後，被告知需交出卡和密碼。",
        ESInform2: "貸款成功郵寄資料到達家中。",
        ESInform3: "這些都是常見的惡意欺詐手法。本公司與這些供應商沒有任何關係。",
        affirmBtn: "立即申請",
        dialogTitle: "授權您的店鋪信息",
        dialogShopImages: "店鋪圖像",
        dialogMail: "登錄郵箱",
        dialogAddress: "店鋪名稱",
        dialogPhone: "證件正面",
        dialogPhone2: "證件反面",
        dialogBtn: "確認，申請貸款",
        message: "未獲取到您的店鋪信息！"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "貸款額度",
            value: "50美元-50000美元",
        },
        {
            label: "貸款期限",
            value: "最長 3 個月，根據貸款金額和商店規模而有所不同",
        },
        {
            label: "借款利率 (日利率）",
            value: "0.01%～10.0%，貸款利率因貸款金額及店鋪維度而變動"
        },
        {
            label: "使用對象",
            value: "商城賣家",
        },
        {
            label: "還款方式",
            value: "聯繫客服溝通",
        },
        {
            label: "擔保方",
            value: "店鋪平台",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "結果反饋",
        hint: "申請已提交，審核結果稍後我們 會以郵箱形式發送給您",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "版本升級，請確認？ ", //版本升級，請確認？
        loadingTitle: "正在下載...", // 正在下載...
        systemUpgrade: "系統升級", // 系統升級
        upgradeImmediately: "確認", // 確認
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "多語言設置",
        affirm: "確認",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "加載中", // 正在加載
        finishedText: "無", //無
        loadingText: "加載中", // 正在加載
    },
};
export default obj;
