<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-08-24 19:28:26
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-20 22:07:04
-->
<template>
  <div id="app">
    <!-- <div class="navigation_bar" @click="navigationWays">
      <img src="./assets/setting.png" alt="" />
    </div> -->
    <!--<navigationBar
      v-if="isShow"
      :isShow="isShow"
      @onChangeActive="onChangeActive"
      @handleClose="handleClose"
    ></navigationBar> -->
    <router-view></router-view>
  </div>
</template>
<script>
// import navigationBar from "./components/drawModule.vue";
import imageUrlApi from "@/components/component/js/imgurlApi.js";
export default {
  components: {
    // navigationBar,
  },
  data() {
    return { isShow: false };
  },

  methods: {
    handleClose(path) {
      this.isShow = path;
    },
    onChangeActive(record) {
      if (record == "settingLanguage") {
        this.$router.push("/language/language-setting");
        this.isShow = false;
      }
      if (record == "shopRegister") {
        this.$JumpShops(imageUrlApi.shopRegisterWebUrl);
      }
      if (record == "shopLogin") {
        this.$JumpShops(imageUrlApi.shopLoginWebUrl);
      }
      if (record == "service") {
        this.$router.push("/serviceIndex");
        this.isShow = false;
      }
    },
    navigationWays() {
      //   this.isShow = true;
      this.$router.push({ path: "/language/language-setting" });
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
#app {
  width: 100%;
  min-height: calc(100vh);
}
.navigation_bar {
  width: 100px;
  height: 100px;
  position: fixed;
  bottom: 400px;
  right: 20px;
  z-index: 3000;
  background-color: rgba(255, 193, 193, 0.5);
  border-radius: 50%;
  box-shadow: 10px 0px 15px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation_bar img {
  width: 62px;
  height: 62px;
}
</style>
