// 土耳其语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Kredi istiyorum",
        navTitlePlaceholder: "15 saniye içinde sonuçlar",
        applicationProcedure: "Başvuru Süreci",
        customerInform: "Yetkili mağaza bilgileri",
        investigate: "anında inceleme",
        submitDocument: "Mağaza Platformu İmza Sözleşmesi",
        transfer: "Mağazaya para kazan",
        withdraw: "Bakiye artışı",
        attention: "* Not: Lütfen kredi koşullarını onayladıktan sonra başvurmayı unutmayınız",
        supportingDocuments: "Mevcut destekleyici belgeler",
        notificationTimeNav: "Denetim Sonuç Bildirimi",
        timeItem1: "Hafta içi",
        timeItem1Title: "Pazartesi ve Pazar tüm gün",
        timeItem2: "Her ayın üçüncü Pazar günü",
        timeItem2Title: "00:00 - 00:20 sistem bakımı",
        timeNotification1: "Denetim sonucu mağazanın giriş posta kutusuna gönderilecek, lütfen zamanında kontrol edin.",
        timeNotification2: "※Onaylanan miktar doğrudan mağazanıza ödenecektir.",
        timeNotification3: "Onay miktarı mağazanın enlemine göre oluşturulur ve beklentilerinizi karşılamayabilir.",
        bMConditionsNav: "Kredi Koşulları", // Kredi Koşulları Başlığı
        bMCContentNav: "Kredi Koşulları",
        bMCContent1: "70 yaşın altında ve sabit bir gelire sahip (bazı gelirler de kabul edilebilir).",
        bMCContent2: "İşlem sırasında 71 yaşında kredi vermeyi durduracağız.",
        bMCHintNav: "Not",
        bMCHint1: "Lütfen kredi koşullarını dikkatlice kontrol edin.",
        bMCHint2: "Şirket tarafından imzalanan kredi işi, Kredi Endüstrisi Derneği'nin Danışma ve Uyuşmazlık Çözüm Merkezi tarafından yürütülmektedir.",
        bMCHint3: "İlgili düzenlemeler yukarıda belirtilmiştir.",
        selectMethodsNav: "0 Faizle Nasıl Kredi Verilir",
        sMInformNav: "Durum",
        sMInform1: 'Mağaza 3 yıldır faaliyet gösteriyor!',
        sMInform2: "Mağazanın 3 yıl içinde sözleşme ihlali yoktur.",
        sMInform3: '3 günden fazla mağaza platformunda alışveriş yapılmamaktadır. ',
        EnhancedNav: "Güvenli Çalışma",
        ESInformNav: "Kötü niyetli taktiklere dikkat edin",
        ESInform1: "※Ödemeyi yaptıktan sonra kartı ve şifreyi teslim etmem söylendi.",
        ESInform2: "Kredi eve başarıyla gönderildi.",
        ESInform3: "Bunlar yaygın kötü niyetli dolandırıcılıklardır. Şirketin bu tedarikçilerle hiçbir ilişkisi yoktur.",
        affirmBtn: "Şimdi başvurun",
        dialogTitle: "Mağaza bilgilerinizi yetkilendirin",
        dialogShopImages: "Görsel Mağazası",
        dialogMail: "Giriş E-postası",
        dialogAddress: "Mağaza Adı",
        dialogPhone: "Sertifika ön yüzü",
        dialogPhone2: "Belgenin ters tarafı",
        dialogBtn: "Onayla, kredi için başvur",
        message: "Mağaza bilgileriniz alınamadı!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Kredi miktarı",
            value: "$50-$50,000",
        },
        {
            label: "Kredi Vadesi",
            value: "3 aya kadar, kredi tutarına ve mağaza büyüklüğüne göre değişir",
        },
        {
            label: "Borçlanma oranı (günlük oran)",
            value: "%0.01~%10.0, kredi faiz oranı kredi tutarına ve mağaza boyutuna göre değişir",
        },
        {
            label: "Nesne kullan",
            value: "AVM satıcısı",
        },
        {
            label: "geri ödeme yöntemi",
            value: "İletişim için müşteri hizmetlerine başvurun",
        },
        {
            label: "Garantör",
            value: "Mağaza Platformu",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Sonuç geri bildirimi",
        hint: "Başvuru gönderildi, inceleme sonucu size daha sonra e-posta ile gönderilecektir",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Sürüm yükseltme, lütfen onaylayın?", //Sürüm yükseltme, lütfen onaylayın?
        loadingTitle: "İndiriliyor...", // İndiriliyor...
        systemUpgrade: "sistem yükseltme", // sistem yükseltme
        upgradeImmediately: "Onayla", // Onayla
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Çok Dilli Ayarlar",
        affirm: "onayla",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Yükleniyor", // Yükleniyor
        finishedText: "yok", //yok
        loadingText: "Yükleniyor", // Yükleniyor
    },
};
export default obj;
