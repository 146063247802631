import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Loans",
    redirect: "/Loans",
  },

  {
    path: "/Application",
    name: "Application",
    component: () => import("@/views/Application.vue"),
    redirect: "/Application/application-index",
    children: [
      {
        path: "/Application/application-index",
        name: "application-index",
        component: () => import("@/views/page/application-index.vue"),
      },
    ],
  },
  {
    path: "/Loans",
    name: "Loans",
    component: () => import("@/views/Loans.vue"),
    redirect: "/Loans/loans-index",
    children: [
      {
        path: "/Loans/loans-index",
        name: "loans-index",
        component: () => import("@/views/page/loans-index.vue"),
      },
      {
        path: "/Loans/feedback-index",
        name: "feedback-index",
        component: () => import("@/views/detail/feedback-index.vue"),
      },
    ],
  },

  {
    path: "/language/language-setting",
    name: "languageSetting",
    component: () => import("@/views/detail/language-shift.vue"),
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  next();
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
