import imgurlApi from './imgurlApi'
let $environment = 'web'

function $JumpShops(params = '') {
    let languageType = window.localStorage.getItem('languageType') || ''

    let code = window.localStorage.getItem('code') == null ? '' : window.localStorage.getItem('code')

    let jumpUrl = imgurlApi.storeHost + '?language=' + languageType + '&' + 'code=' + code + params + '';

    if (!params.includes('?')) {
        jumpUrl = jumpUrl + '?';
    }

    window.location.href = jumpUrl + '&code=' + code + '&language=' + languageType;
}
export default {
    $environment,
    $JumpShops
}