// 阿拉伯语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "أريد قرضًا",
        navTitlePlaceholder: "النتائج خلال 15 ثانية",
        applicationProcedure: "عملية التقديم",
        customerInform: "معلومات المتجر المعتمد",
        investigate: "مراجعة فورية",
        submitDocument: "اتفاقية توقيع منصة المتجر",
        transfer: "كسب المال إلى المتجر",
        withdraw: "زيادة الرصيد",
        attention: "* ملاحظة: يرجى التأكد من التقديم بعد التأكد من شروط القرض",
        supportingDocuments: "المستندات الداعمة المتوفرة",
        notificationTimeNav: "إشعار نتيجة التدقيق",
        timeItem1: "أيام الأسبوع",
        timeItem1Title: "الاثنين والأحد طوال اليوم",
        timeItem2: "الأحد الثالث من كل شهر",
        timeItem2Title: "من 00:00 إلى 00:20 صيانة النظام",
        timeNotification1: "سيتم إرسال نتيجة التدقيق إلى صندوق بريد تسجيل الدخول إلى المتجر , يرجى التحقق منه في الوقت المناسب.",
        timeNotification2: "سيتم دفع المبلغ المعتمد مباشرة إلى متجرك.",
        timeNotification3: "يتم إنشاء مبلغ الموافقة وفقًا لخط عرض المتجر وقد لا يلبي توقعاتك.",
        bMConditionsNav: "شروط القرض", // عنوان شروط القرض
        bMCContentNav: "شروط القرض",
        bMCContent1: "تحت سن 70 ولديك دخل ثابت (بعض الدخل مقبول أيضًا).",
        bMCContent2: "في سن 71 أثناء المعاملة , سنتوقف عن الإقراض.",
        bMCHintNav: "ملاحظة",
        bMCHint1: "الرجاء التحقق من شروط القرض بعناية.",
        bMCHint2: "يتم التعامل مع أعمال القرض الموقعة من قبل الشركة من قبل مركز الاستشارات وحل النزاعات التابع لرابطة صناعة القروض.",
        bMCHint3: "اللوائح ذات الصلة المذكورة أعلاه",
        selectMethodsNav: "كيفية الاقتراض بدون فائدة",
        sMInformNav: "الحالة",
        sMInform1: "المتجر يعمل منذ 3 سنوات!",
        sMInform2: "المتجر ليس لديه خرق للعقد خلال 3 سنوات.",
        sMInform3: 'لا توجد عملية شراء داخل منصة المتجر لأكثر من 3 أيام',
        EnhancedNav: "التشغيل الآمن",
        ESInformNav: "احترس من التكتيكات الضارة",
        ESInform1: "※ بعد إجراء الدفع , طُلب مني تسليم البطاقة وكلمة المرور.",
        ESInform2: "تم إرسال القرض إلى المنزل عبر البريد بنجاح.",
        ESInform3: "هذه عمليات احتيال ضارة شائعة. ليس للشركة علاقة بهؤلاء الموردين.",
        affirmBtn: "اشترك الآن",
        dialogTitle: "تفويض معلومات متجرك",
        dialogShopImages: "تسوق الصور",
        dialogMail: "البريد الإلكتروني لتسجيل الدخول",
        dialogAddress: "اسم المتجر",
        dialogPhone: "واجهة الشهادة",
        dialogPhone2: "الجانب الخلفي من المستند",
        dialogBtn: "تأكيد , التقدم بطلب للحصول على قرض",
        message: "لم يتم الحصول على معلومات متجرك!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "مبلغ القرض",
            value: "من 50 إلى 50000 دولار",
        },
        {
            label: "فترة قرض",
            value: "حتى 3 أشهر ، تختلف حسب مبلغ القرض وحجم المتجر",
        },
        {
            label: "معدل الاقتراض (السعر اليومي)",
            value: "0.01٪ 10.0٪ , يختلف سعر الفائدة على القرض وفقًا لمبلغ القرض وأبعاد المتجر",
        },
        {
            label: "استخدام الكائن",
            value: "بائع مول",
        },
        {
            label: "طريقة السداد",
            value: "تواصل مع خدمة العملاء للتواصل",
        },
        {
            label: "كفيل",
            value: "منصة التسوق",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "نتيجة التغذية المرتدة",
        hint: "تم تقديم الطلب , وسيتم إرسال نتيجة المراجعة إليك لاحقًا عبر البريد الإلكتروني",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "ترقية الإصدار , يرجى التأكيد؟", // ترقية الإصدار , يرجى التأكيد؟
        loadingTitle: "جارٍ التنزيل ...", // جارٍ التنزيل ...
        systemUpgrade: "ترقية النظام", // ترقية النظام
        upgradeImmediately: "تأكيد", // تأكيد
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "إعدادات متعددة اللغات",
        affirm: "تؤكد",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "تحميل", // تحميل
        finishedText: "لا شيء", // لا شيء
        loadingText: "تحميل", // تحميل
    },
};
export default obj;
