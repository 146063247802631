// 泰语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "ฉันต้องการเงินกู้",
        navTitlePlaceholder: "ผลลัพธ์ภายใน 15 วินาที",
        applicationProcedure: "ขั้นตอนการสมัคร",
        customerInform: "ข้อมูลร้านค้าที่ได้รับอนุญาต",
        investigate: "ตรวจสอบทันที",
        submitDocument: "ข้อตกลงการลงนามแพลตฟอร์มร้านค้า",
        transfer: "หาเงินเข้าร้าน",
        withdraw: "เพิ่มยอดคงเหลือ",
        attention: "* หมายเหตุ: โปรดสมัครหลังจากยืนยันเงื่อนไขการกู้ยืม",
        supportingDocuments: "เอกสารประกอบที่มีอยู่",
        notificationTimeNav: "แจ้งผลการตรวจสอบ",
        timeItem1: "วันธรรมดา",
        timeItem1Title: "วันจันทร์และวันอาทิตย์ทั้งวัน",
        timeItem2: "วันอาทิตย์ที่สามของทุกเดือน",
        timeItem2Title: "00:00 ถึง 00:20 การบำรุงรักษาระบบ",
        timeNotification1: "ผลการตรวจสอบจะถูกส่งไปยังกล่องจดหมายสำหรับเข้าสู่ระบบของร้านค้า โปรดตรวจสอบให้ทันเวลา",
        timeNotification2: "※จำนวนเงินที่อนุมัติจะจ่ายโดยตรงไปยังร้านค้าของคุณ",
        timeNotification3: "จำนวนการอนุมัติถูกสร้างขึ้นตามละติจูดของร้านค้า และอาจไม่ตรงกับความคาดหวังของคุณ",
        bMConditionsNav: "เงื่อนไขเงินกู้", // ชื่อเงื่อนไขเงินกู้
        bMCContentNav: "เงื่อนไขการยืม",
        bMCContent1: "อายุต่ำกว่า 70 ปีและมีรายได้ที่มั่นคง (รายได้บางส่วนก็รับได้)",
        bMCContent2: "ตอนอายุ 71 ระหว่างการทำธุรกรรม เราจะหยุดให้ยืม",
        bMCHintNav: "หมายเหตุ",
        bMCHint1: "โปรดตรวจสอบเงื่อนไขการกู้ยืมอย่างละเอียด",
        bMCHint2: "ธุรกิจสินเชื่อที่ลงนามโดยบริษัทได้รับการจัดการโดยศูนย์ให้คำปรึกษาและระงับข้อพิพาทของ Loan Industry Association",
        bMCHint3: "กฎระเบียบที่เกี่ยวข้องระบุไว้ข้างต้น",
        selectMethodsNav: "วิธีกู้เงินแบบ 0 ดอกเบี้ย",
        sMInformNav: "เงื่อนไข",
        sMInform1: 'ร้านเปิดทำการมา 3 ปีแล้ว!',
        sMInform2: "ร้านค้าไม่มีการผิดสัญญาภายใน 3 ปี",
        sMInform3: 'ไม่มีการซื้อภายในแพลตฟอร์มร้านค้านานกว่า 3 วัน ',
        EnhancedNav: "การทำงานที่ปลอดภัย",
        ESInformNav: "ระวังกลยุทธ์ที่เป็นอันตราย",
        ESInform1: "※หลังจากชำระเงินแล้ว ฉันได้รับแจ้งให้มอบบัตรและรหัสผ่าน",
        ESInform2: "เงินกู้ถูกส่งไปที่บ้านเรียบร้อยแล้ว",
        ESInform3: "สิ่งเหล่านี้เป็นการหลอกลวงที่เป็นอันตรายทั่วไป บริษัทไม่มีความสัมพันธ์กับซัพพลายเออร์เหล่านี้",
        affirmBtn: "สมัครเลย",
        dialogTitle: "อนุญาตข้อมูลร้านค้าของคุณ",
        dialogShopImages: "รูปภาพร้านค้า",
        dialogMail: "อีเมลสำหรับเข้าสู่ระบบ",
        dialogAddress: "ชื่อร้าน",
        dialogPhone: "หน้าใบรับรอง",
        dialogPhone2: "ด้านหลังของเอกสาร",
        dialogBtn: "ยืนยัน สมัครสินเชื่อ",
        message: "ไม่ได้รับข้อมูลร้านค้าของคุณ!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "วงเงินกู้",
            value: "$50-$50,000",
        },
        {
            label: "ระยะเวลาเงินกู้",
            value: "สูงสุด 3 เดือน แตกต่างกันไปตามจำนวนเงินกู้และขนาดร้าน",
        },
        {
            label: "อัตราการยืม (รายวัน)",
            value: "0.01%～10.0% อัตราดอกเบี้ยเงินกู้จะแตกต่างกันไปตามจำนวนเงินกู้และขนาดร้านค้า",
        },
        {
            label: "ใช้วัตถุ",
            value: "ผู้ขายห้างสรรพสินค้า",
        },
        {
            label: "วิธีการชำระคืน",
            value: "ติดต่อฝ่ายบริการลูกค้าเพื่อสื่อสาร",
        },
        {
            label: "ผู้ค้ำประกัน",
            value: "แพลตฟอร์มร้านค้า",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "ความคิดเห็นเกี่ยวกับผลลัพธ์",
        hint: "ส่งใบสมัครแล้ว ผลการตรวจสอบจะส่งให้คุณในภายหลังทางอีเมล",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "อัพเกรดเวอร์ชั่น กรุณายืนยัน?", // อัพเกรดเวอร์ชั่น กรุณายืนยัน?
        loadingTitle: "กำลังดาวน์โหลด...", // กำลังดาวน์โหลด...
        systemUpgrade: "อัพเกรดระบบ", // อัพเกรดระบบ
        upgradeImmediately: "ยืนยัน", // ยืนยัน
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "การตั้งค่าหลายภาษา",
        affirm: "ยืนยัน",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "กำลังโหลด", // กำลังโหลด
        finishedText: "ไม่มี", //ไม่มี
        loadingText: "กำลังโหลด", // กำลังโหลด
    },
};
export default obj;
