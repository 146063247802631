// 荷兰语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Ik wil een lening",
        navTitlePlaceholder: "Resultaten binnen 15 seconden",
        applicationProcedure: "Aanvraagproces",
        customerInform: "Geautoriseerde winkelinformatie",
        investigate: "onmiddellijke beoordeling",
        submitDocument: "Ondertekeningsovereenkomst winkelplatform",
        transfer: "Verdien geld naar de winkel",
        withdraw: "Saldo verhogen",
        attention: "* Let op: zorg ervoor dat u zich aanmeldt nadat u de leningsvoorwaarden hebt bevestigd",
        supportingDocuments: "Beschikbare ondersteunende documenten",
        notificationTimeNav: "Notificatie van auditresultaat",
        timeItem1: "Weekdagen",
        timeItem1Title: "Maandag en zondag de hele dag",
        timeItem2: "De derde zondag van elke maand",
        timeItem2Title: "00:00 tot 00:20 systeemonderhoud",
        timeNotification1: "Het auditresultaat wordt naar de login-mailbox van de winkel gestuurd, controleer dit op tijd.",
        timeNotification2: "※Het goedgekeurde bedrag wordt rechtstreeks aan uw winkel betaald.",
        timeNotification3: "Het goedkeuringsbedrag wordt gegenereerd op basis van de speelruimte van de winkel en voldoet mogelijk niet aan uw verwachtingen.",
        bMConditionsNav: "Leenvoorwaarden", // Titel leningvoorwaarden
        bMCContentNav: "Leenvoorwaarden",
        bMCContent1: "Onder de 70 jaar en een stabiel inkomen hebben (sommige inkomsten zijn ook acceptabel)",
        bMCContent2: "Op 71-jarige leeftijd tijdens de transactie stoppen we met lenen.",
        bMCHintNav: "Opmerking",
        bMCHint1: "Controleer de leningsvoorwaarden zorgvuldig.",
        bMCHint2: "De door het bedrijf ondertekende leningzaken worden afgehandeld door het Consultation and Dispute Resolution Centre van de Loan Industry Association.",
        bMCHint3: "De relevante voorschriften staan hierboven vermeld.",
        selectMethodsNav: "Hoe te lenen met 0 rente",
        sMInformNav: "Voorwaarde",
        sMInform1: 'De winkel bestaat al 3 jaar!',
        sMInform2: "De winkel heeft binnen 3 jaar geen contractbreuk.",
        sMInform3: 'Er is meer dan 3 dagen geen aankoop gedaan binnen het winkelplatform. ',
        EnhancedNav: "Veilig gebruik",
        ESInformNav: "Pas op voor kwaadaardige tactieken",
        ESInform1: "※Nadat ik de betaling had gedaan, kreeg ik te horen dat ik de kaart en het wachtwoord moest overhandigen.",
        ESInform2: "De lening is met succes naar het huis gemaild.",
        ESInform3: "Dit zijn veelvoorkomende kwaadaardige oplichting. Het bedrijf heeft geen relatie met deze leveranciers.",
        affirmBtn: "Solliciteer nu",
        dialogTitle: "Autoriseer uw winkelinformatie",
        dialogShopImages: "Winkel afbeeldingen",
        dialogMail: "Inlog-e-mail",
        dialogAddress: "Winkelnaam",
        dialogPhone: "Certificaat voorzijde",
        dialogPhone2: "Achterzijde van document",
        dialogBtn: "Bevestigen, lening aanvragen",
        message: "Uw winkelinformatie is niet verkregen!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Leenbedrag",
            value: "$50-$50,000",
        },
        {
            label: "Lening termijn",
            value: "Tot 3 maanden, afhankelijk van het geleende bedrag en de grootte van de winkel",
        },
        {
            label: "Leningstarief (dagtarief)",
            value: "0,01%~10,0%, de leningrente varieert afhankelijk van het geleende bedrag en de winkeldimensie",
        },
        {
            label: "Voorwerp gebruiken",
            value: "Winkelcentrum verkoper",
        },
        {
            label: "Terugbetalingsmethode",
            value: "Neem contact op met de klantenservice om te communiceren",
        },
        {
            label: "Borg",
            value: "Winkel platform",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Resultaatfeedback",
        hint: "De aanvraag is ingediend, het beoordelingsresultaat wordt u later per e-mail toegestuurd",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Versie-upgrade, gelieve te bevestigen?", //Versie-upgrade, gelieve te bevestigen?
        loadingTitle: "Downloaden...", // Downloaden...
        systemUpgrade: "systeemupgrade", // systeemupgrade
        upgradeImmediately: "Bevestigen", // Bevestigen
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Meertalige instellingen",
        affirm: "bevestigen",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Laden", // Laden
        finishedText: "geen", // geen
        loadingText: "Bezig met laden", // Bezig met laden
    },
};
export default obj;
