// 法语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Je veux un prêt",
        navTitlePlaceholder: "Résultats en 15 secondes",
        applicationProcedure: "Processus de candidature",
        customerInform: "Informations sur le magasin autorisé",
        investigate: "examen instantané",
        submitDocument: "Accord de signature de la plate-forme de boutique",
        transfer: "Gagner de l'argent au magasin",
        withdraw: "Augmentation du solde",
        attention: "*Remarque: Assurez-vous de postuler après avoir confirmé les conditions du prêt",
        supportingDocuments: "Documents justificatifs disponibles",
        notificationTimeNav: "Notification de résultat d'audit",
        timeItem1: " Jours de la semaine ",
        timeItem1Title: "Lundi et dimanche toute la journée",
        timeItem2: "Le troisième dimanche de chaque mois",
        timeItem2Title: "maintenance du système de 00:00 à 00:20",
        timeNotification1: "Le résultat de l'audit sera envoyé à la boîte aux lettres de connexion du magasin, veuillez le vérifier à temps.",
        timeNotification2: "※Le montant approuvé sera payé directement à votre magasin.",
        timeNotification3: "Le montant de l'approbation est généré en fonction de la latitude du magasin et peut ne pas répondre à vos attentes.",
        bMConditionsNav: "Conditions de prêt", // Titre des conditions de prêt
        bMCContentNav: "Conditions de prêt",
        bMCContent1: "Vous avez moins de 70ans et vous avez un revenu stable (certains revenus sont également acceptables).",
        bMCContent2: "A l'âge de 71ans lors de la transaction, nous arrêterons de prêter.",
        bMCHintNav: "Remarque",
        bMCHint1: "Veuillez vérifier attentivement les conditions du prêt.",
        bMCHint2: "L'activité de prêt signée par l'entreprise est gérée par le Centre de consultation et de règlement des différends de la Loan Industry Association.",
        bMCHint3: "Les réglementations applicables sont mentionnées ci-dessus.",
        selectMethodsNav: "Comment emprunter avec 0 intérêt",
        sMInformNav: "État",
        sMInform1: 'Le magasin fonctionne depuis 3 ans!',
        sMInform2: "Le magasin n'a pas de rupture de contrat dans les 3 ans.",
        sMInform3: 'Il n\'y a pas d\'achat dans la plateforme du magasin pendant plus de 3 jours. ',
        EnhancedNav: 'Fonctionnement en toute sécurité',
        ESInformNav: "Attention aux tactiques malveillantes",
        ESInform1: "※Après avoir effectué le paiement, on m'a dit de remettre la carte et le mot de passe.",
        ESInform2: "Le prêt a été envoyé avec succès au domicile.",
        ESInform3: "Ce sont des escroqueries malveillantes courantes. L'entreprise n'a aucune relation avec ces fournisseurs.",
        affirmBtn: "Appliquez maintenant",
        dialogTitle: "Autoriser les informations de votre magasin",
        dialogShopImages: "Images de la boutique",
        dialogMail: "E-mail de connexion",
        dialogAddress: "Nom du magasin",
        dialogPhone: "Certificat avant",
        dialogPhone2: "Verso du document",
        dialogBtn: "Confirmer, demander un prêt",
        message: "Les informations sur votre magasin n'ont pas été obtenues!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Montant du prêt",
            value: "50$ - 50000$",
        },
        {
            label: "Durée du prêt",
            value: "Jusqu'à 3 mois, varie selon le montant du prêt et la taille du magasin",
        },
        {
            label: "Taux débiteur (taux journalier)",
            value: "0,01% ～ 10,0%, le taux d'intérêt du prêt varie en fonction du montant du prêt et de la dimension du magasin",
        },
        {
            label: "Utiliser l'objet",
            value: "Vendeur du centre commercial",
        },
        {
            label: "Méthode de remboursement",
            value: "Contactez le service client pour communiquer",
        },
        {
            label: "Garant",
            value: "Plateforme de la boutique",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Retour sur les résultats",
        hint: "La candidature a été soumise, le résultat de l'examen vous sera envoyé ultérieurement par e-mail",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Mise à niveau de la version, veuillez confirmer?", //Mise à niveau de la version, veuillez confirmer?
        loadingTitle: "Téléchargement...", // Téléchargement...
        systemUpgrade: "mise à niveau du système", // mise à niveau du système
        upgradeImmediately: "Confirmer", // Confirmer
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Paramètres multilingues",
        affirm: "confirmer",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Chargement", // Chargement
        finishedText: "aucun", //aucun
        loadingText: "Chargement", // Chargement
    },
};
export default obj;
