// 德语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Ich möchte einen Kredit",
        navTitlePlaceholder: "Ergebnisse innerhalb von 15 Sekunden",
        applicationProcedure: "Bewerbungsverfahren",
        customerInform: "Informationen zum autorisierten Geschäft",
        investigate: "sofortige Überprüfung",
        submitDocument: "Vertrag zur Unterzeichnung der Shop-Plattform",
        transfer: "Geld in den Laden bringen",
        withdraw: "Guthaben erhöhen",
        attention: "* Hinweis: Bitte unbedingt nach Bestätigung der Ausleihkonditionen beantragen",
        supportingDocuments: "Verfügbare Belege",
        notificationTimeNav: "Audit-Ergebnisbenachrichtigung",
        timeItem1: "Wochentage",
        timeItem1Title: "Montag und Sonntag den ganzen Tag",
        timeItem2: "Jeden dritten Sonntag im Monat",
        timeItem2Title: "00:00 bis 00:20 Systemwartung",
        timeNotification1: "Das Prüfungsergebnis wird an das Login-Postfach des Shops gesendet, bitte überprüfen Sie es rechtzeitig.",
        timeNotification2: "※Der genehmigte Betrag wird direkt an Ihr Geschäft gezahlt.",
        timeNotification3: "Der Genehmigungsbetrag wird gemäß dem Breitengrad des Geschäfts generiert und entspricht möglicherweise nicht Ihren Erwartungen.",
        bMConditionsNav: "Darlehensbedingungen", // Titel der Darlehensbedingungen
        bMCContentNav: "Ausleihkonditionen",
        bMCContent1: "Unter 70 Jahre alt und mit stabilem Einkommen (ein gewisses Einkommen ist auch akzeptabel).",
        bMCContent2: "Im Alter von 71 Jahren während der Transaktion werden wir die Kreditvergabe einstellen.",
        bMCHintNav: "Hinweis",
        bMCHint1: "Bitte prüfen Sie die Ausleihbedingungen sorgfältig.",
        bMCHint2: 'Das vom Unternehmen abgeschlossene Kreditgeschäft wird von der Beratungs- und Streitbeilegungsstelle der Loan Industry Association abgewickelt.',
        bMCHint3: "Die relevanten Vorschriften sind oben erwähnt.",
        selectMethodsNav: "So leihen Sie mit 0 Zinsen",
        sMInformNav: "Zustand",
        sMInform1: 'Der Shop ist seit 3 ​​Jahren in Betrieb!',
        sMInform2: "Der Shop hat innerhalb von 3 Jahren keinen Vertragsbruch.",
        sMInform3: 'Auf der Store-Plattform gibt es seit mehr als 3 Tagen keinen Kauf. ',
        EnhancedNav: "Sicherer Betrieb",
        ESInformNav: "Achten Sie auf böswillige Taktiken",
        ESInform1: "※Nach der Zahlung wurde ich aufgefordert, die Karte und das Passwort zu übergeben.",
        ESInform2: "Das Darlehen wurde erfolgreich an das Heim geschickt.",
        ESInform3: "Dies sind häufige böswillige Betrügereien. Das Unternehmen hat keine Beziehung zu diesen Lieferanten.",
        affirmBtn: "Jetzt bewerben",
        dialogTitle: "Autorisieren Sie Ihre Geschäftsinformationen",
        dialogShopImages: "Shop-Bilder",
        dialogMail: "Login-E-Mail",
        dialogAddress: "Geschäftsname",
        dialogPhone: "Zertifikat vorne",
        dialogPhone2: "Rückseite des Dokuments",
        dialogBtn: "Bestätigen, Kredit beantragen",
        message: "Ihre Geschäftsinformationen wurden nicht abgerufen!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Darlehensbetrag",
            value: "$50-$50.000",
        },
        {
            label: "Darlehenslaufzeit",
            value: 'Bis zu 3 Monate, variiert je nach Kredithöhe und Ladengröße',
        },
        {
            label: "Sollzinssatz (Tagessatz)",
            value: "0,01 % ~ 10,0 %, der Kreditzinssatz variiert je nach Kreditbetrag und Filialgröße",
        },
        {
            label: "Objekt verwenden",
            value: "Verkäufer im Einkaufszentrum",
        },
        {
            label: "Rückzahlungsmethode",
            value: "Wenden Sie sich zur Kommunikation an den Kundendienst",
        },
        {
            label: "Garantiegeber",
            value: "Shop-Plattform",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Ergebnis-Feedback",
        hint: "Die Bewerbung wurde eingereicht, das Prüfergebnis wird Ihnen später per E-Mail zugeschickt",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Versions-Upgrade, bitte bestätigen?", //Versions-Upgrade, bitte bestätigen?
        loadingTitle: "Wird heruntergeladen...", // Wird heruntergeladen...
        systemUpgrade: "System-Upgrade", // System-Upgrade
        upgradeImmediately: "Bestätigen", // Bestätigen
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Mehrsprachige Einstellungen",
        affirm: "bestätigen",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Laden", // Wird geladen
        finishedText: "keine", //keine
        loadingText: "Laden", // Laden
    },
};
export default obj;
