// 俄语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Хочу кредит",
        navTitlePlaceholder: "Результаты в течение 15 секунд",
        applicationProcedure: 'Процесс подачи заявки',
        customerInform: "Информация об авторизованном магазине",
        investigate: "мгновенный обзор",
        submitDocument: "Соглашение о подписании платформы магазина",
        transfer: "Заработай в магазин",
        withdraw: "Увеличение баланса",
        attention: "* Примечание: обязательно подайте заявку после подтверждения условий кредита",
        supportingDocuments: "Имеющиеся подтверждающие документы",
        notificationTimeNav: "Уведомление о результатах аудита",
        timeItem1: "Будни",
        timeItem1Title: "Понедельник и воскресенье весь день",
        timeItem2: "Третье воскресенье каждого месяца",
        timeItem2Title: "Техническое обслуживание системы с 00:00 до 00:20",
        timeNotification1: "Результат аудита будет отправлен на почтовый ящик входа в магазин, пожалуйста, проверьте его вовремя.",
        timeNotification2: "※Утвержденная сумма будет выплачена непосредственно в ваш магазин.",
        timeNotification3: "Сумма одобрения формируется в зависимости от широты магазина и может не соответствовать вашим ожиданиям.",
        bMConditionsNav: 'Условия займа', // Заголовок условий займа
        bMCContentNav: 'Условия займа',
        bMCContent1: "В возрасте до 70 лет и иметь стабильный доход (некоторый доход также приемлем).",
        bMCContent2: "В возрасте 71 года во время транзакции мы прекратим кредитование.",
        bMCHintNav: "Примечание",
        bMCHint1: "Пожалуйста, внимательно ознакомьтесь с условиями кредита.",
        bMCHint2: 'Кредитный бизнес, подписанный компанией, находится в ведении Центра консультаций и разрешения споров Ассоциации кредитной индустрии.',
        bMCHint3: "Соответствующие правила указаны выше.",
        selectMethodsNav: "Как получить кредит с нулевой процентной ставкой",
        sMInformNav: "Состояние",
        sMInform1: 'Магазин работает 3 года!',
        sMInform2: "Магазин не нарушал договор в течение 3 лет.",
        sMInform3: 'В платформе магазина нет покупок более 3-х дней. ',
        EnhancedNav: 'Безопасная эксплуатация',
        ESInformNav: 'Остерегайтесь вредоносных тактик',
        ESInform1: "※После оплаты мне сказали сдать карту и пароль.",
        ESInform2: "Заем успешно отправлен на дом.",
        ESInform3: "Это обычные злонамеренные мошенничества. Компания не имеет отношения к этим поставщикам.",
        affirmBtn: "Подать заявку сейчас",
        dialogTitle: "Авторизовать информацию о вашем магазине",
        dialogShopImages: "Магазин изображений",
        dialogMail: "Электронная почта для входа",
        dialogAddress: "Имя магазина",
        dialogPhone: "Сертификат спереди",
        dialogPhone2: "Обратная сторона документа",
        dialogBtn: "Подтвердить, подать заявку на кредит",
        message: "Информация о вашем магазине не получена!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Величина займа",
            value: "50-50 000 долларов США",
        },
        {
            label: "Срок кредита",
            value: "До 3 месяцев, зависит от суммы кредита и размера магазина",
        },
        {
            label: "Процентная ставка (дневная ставка)",
            value: "0,01% ~ 10,0%, процентная ставка по кредиту варьируется в зависимости от суммы кредита и размера магазина",
        },
        {
            label: "Использовать объект",
            value: "Продавец торгового центра",
        },
        {
            label: "Способ погашения",
            value: "Свяжитесь со службой поддержки для связи",
        },
        {
            label: "Гарант",
            value: "Платформа магазина",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Отзыв о результатах",
        hint: "Заявка отправлена, результат рассмотрения будет отправлен вам позже по электронной почте",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Обновление версии, подтвердите?", //Обновление версии, подтвердите?
        loadingTitle: "Скачивание...", // Скачивание...
        systemUpgrade: "обновление системы", // обновление системы
        upgradeImmediately: "Подтвердить", // Подтвердить
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Многоязычные настройки",
        affirm: "подтверждать",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Loading", // Loading
        finishedText: "none", //none
        loadingText: "Loading", // Loading
    },
};
export default obj;
