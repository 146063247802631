// 意大利语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Voglio un prestito",
        navTitlePlaceholder: "Risultati entro 15 secondi",
        applicationProcedure: "Processo di candidatura",
        customerInform: "Informazioni negozio autorizzato",
        investigate: "revisione immediata",
        submitDocument: "Contratto di firma della piattaforma del negozio",
        transfer: "Fai soldi al negozio",
        withdraw: "Aumento del saldo",
        attention: "* Nota: assicurati di fare domanda dopo aver confermato le condizioni del prestito",
        supportingDocuments: "Documenti giustificativi disponibili",
        notificationTimeNav: "Notifica risultato audit",
        timeItem1: "Giorni feriali",
        timeItem1Title: "Lunedì e domenica tutto il giorno",
        timeItem2: "La terza domenica di ogni mese",
        timeItem2Title: "Dalle 00:00 alle 00:20 manutenzione del sistema",
        timeNotification1: "Il risultato dell'audit verrà inviato alla casella di posta di accesso del negozio, controllalo in tempo.",
        timeNotification2: "※L'importo approvato verrà pagato direttamente al tuo negozio.",
        timeNotification3: "L'importo dell'approvazione viene generato in base alla latitudine del negozio e potrebbe non soddisfare le tue aspettative.",
        bMConditionsNav: "Condizioni di prestito", // Titolo delle condizioni di prestito
        bMCContentNav: "Condizioni di prestito",
        bMCContent1: "Ha meno di 70 anni e ha un reddito stabile (anche un certo reddito è accettabile).",
        bMCContent2: "All'età di 71 anni durante la transazione, smetteremo di prestare.",
        bMCHintNav: "Nota",
        bMCHint1: "Si prega di controllare attentamente le condizioni del prestito.",
        bMCHint2: "L'attività di prestito firmata dalla società è gestita dal Centro di consultazione e risoluzione delle controversie della Loan Industry Association.",
        bMCHint3: "I regolamenti pertinenti sono menzionati sopra.",
        selectMethodsNav: "Come prestare con 0 interessi",
        sMInformNav: "Condizione",
        sMInform1: 'Il negozio è operativo da 3 anni!',
        sMInform2: "Il negozio non ha alcuna violazione del contratto entro 3 anni.",
        sMInform3: 'Non ci sono acquisti all\'interno della piattaforma del negozio per più di 3 giorni. ',
        EnhancedNav: "Operazione sicura",
        ESInformNav: "Attenzione alle tattiche dannose",
        ESInform1: "※Dopo aver effettuato il pagamento, mi è stato detto di consegnare la carta e la password.",
        ESInform2: "Il prestito è stato spedito con successo a casa.",
        ESInform3: "Queste sono truffe dannose comuni. L'azienda non ha alcun rapporto con questi fornitori.",
        affirmBtn: "Fai domanda ora",
        dialogTitle: "Autorizza le informazioni del tuo negozio",
        dialogShopImages: "Immagini del negozio",
        dialogMail: "E-mail di accesso",
        dialogAddress: "Nome negozio",
        dialogPhone: "Certificato anteriore",
        dialogPhone2: "Retro del documento",
        dialogBtn: "Conferma, richiedi prestito",
        message: "Le informazioni del tuo negozio non sono state ottenute!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Importo del prestito",
            value: "$ 50-$ 50.000",
        },
        {
            label: "Durata del prestito",
            value: "Fino a 3 mesi, varia in base all'importo del prestito e alle dimensioni del negozio",
        },
        {
            label: "Tasso di prestito (tasso giornaliero)",
            value: "0,01%～10,0%, il tasso di interesse del prestito varia in base all'importo del prestito e alla dimensione del negozio",
        },
        {
            label: "Usa oggetto",
            value: "Venditore del centro commerciale",
        },
        {
            label: "Metodo di rimborso",
            value: "Contatta il servizio clienti per comunicare",
        },
        {
            label: "Garante",
            value: "Piattaforma negozio",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Feedback sui risultati",
        hint: "La domanda è stata inviata, il risultato della revisione ti verrà inviato in seguito via e-mail",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Aggiornamento versione, conferma?", //Aggiornamento versione, conferma?
        loadingTitle: "Download in corso...", // Download in corso...
        systemUpgrade: "aggiornamento del sistema", // aggiornamento del sistema
        upgradeImmediately: "Conferma", // Conferma
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Impostazioni multilingue",
        affirm: "confermare",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Caricamento in corso", // Caricamento in corso
        finishedText: "nessuno", //nessuno
        loadingText: "Caricamento in corso", // Caricamento in corso
    },
};
export default obj;
