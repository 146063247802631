// 芬兰语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Haluan lainaa",
        navTitlePlaceholder: "Tulokset 15 sekunnin sisällä",
        applicationProcedure: "Hakuprosessi",
        customerInform: "Valtuutetun kaupan tiedot",
        investigate: "välitön tarkistus",
        submitDocument: "Shop Platform Signing Agreement",
        transfer: "Ansaitse rahaa kauppaan",
        withdraw: "saldon lisäys",
        attention: "* Huomautus: Muista hakea lainaehtojen vahvistamisen jälkeen",
        supportingDocuments: "Saatavilla olevat tukiasiakirjat",
        notificationTimeNav: "Tarkastuksen tulosilmoitus",
        timeItem1: "Arkipäivät",
        timeItem1Title: "Maanantai ja sunnuntai koko päivän",
        timeItem2: "Joka kuukauden kolmas sunnuntai",
        timeItem2Title: "00:00 - 00:20 järjestelmän ylläpito",
        timeNotification1: "Tarkastustulos lähetetään myymälän kirjautumispostilaatikkoon, tarkista se ajoissa.",
        timeNotification2: "※Hyväksytty summa maksetaan suoraan myymälällesi.",
        timeNotification3: "Hyväksyntäsumma luodaan myymälän leveysasteen mukaan, eikä se välttämättä vastaa odotuksiasi.",
        bMConditionsNav: "Lainaehdot", // Lainaehtojen otsikko
        bMCContentNav: "Lainaehdot",
        bMCContent1: "Alle 70-vuotias ja vakaat tulot (jotkut tulot ovat myös hyväksyttäviä).",
        bMCContent2: "Lopetamme lainaamisen 71-vuotiaana kaupan aikana.",
        bMCHintNav: "Huomaa",
        bMCHint1: "Tarkista lainaehdot huolellisesti.",
        bMCHint2: "Yhtiön allekirjoittaman lainaliiketoiminnan hoitaa Lainatoimialaliiton Neuvottelu- ja riitojenratkaisukeskus.",
        bMCHint3: "Sopivat määräykset on mainittu edellä.",
        selectMethodsNav: "Kuinka lainata 0 korolla",
        sMInformNav: "Kunto",
        sMInform1: 'Kauppa on toiminut 3 vuotta!',
        sMInform2: "Kauppa ei ole rikkonut sopimusta 3 vuoden sisällä.",
        sMInform3: 'Kauppa-alustalla ei ole tehty ostoksia yli 3 päivään. ',
        EnhancedNav: "Turvallinen käyttö",
        ESInformNav: "Varo haitallisia taktiikoita",
        ESInform1: "※Maksun suorittamisen jälkeen minua käskettiin luovuttamaan kortti ja salasana.",
        ESInform2: "Laina postitettiin onnistuneesti kotiin.",
        ESInform3: "Nämä ovat yleisiä haitallisia huijauksia. Yrityksellä ei ole suhdetta näihin toimittajiin.",
        affirmBtn: "Hae nyt",
        dialogTitle: "Valtuuta myymäläsi tiedot",
        dialogShopImages: "Osta kuvia",
        dialogMail: "Kirjautumissähköposti",
        dialogAddress: "Kaupan nimi",
        dialogPhone: "Sertifikaatti edessä",
        dialogPhone2: "Asiakirjan kääntöpuoli",
        dialogBtn: "Vahvista, hae lainaa",
        message: "Liikenne tietoja ei ole saatu!",
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Lainan määrä",
            value: "50-50 000 dollaria",
        },
        {
            label: "Laina-aika",
            value: "Jopa 3 kuukautta, vaihtelee lainasumman ja myymälän koon mukaan",
        },
        {
            label: "Lainakorko (päiväkorko)",
            value: "0,01%～10,0%, lainan korko vaihtelee lainasumman ja myymäläulottuvuuden mukaan",
        },
        {
            label: "Käytä objektia",
            value: "Mall myyjä",
        },
        {
            label: "Takaisinmaksutapa",
            value: "Ota yhteyttä asiakaspalveluun kommunikoidaksesi",
        },
        {
            label: "Takaaja",
            value: "Shop Platform",

        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Tulospalaute",
        hint: "Hakemus on lähetetty, tarkistuksen tulos lähetetään sinulle myöhemmin sähköpostitse",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Version päivitys, vahvista?", //Version päivitys, vahvista?
        loadingTitle: "Ladataan...", // Ladataan...
        systemUpgrade: "järjestelmän päivitys", // järjestelmän päivitys
        upgradeImmediately: "Vahvista", // Vahvista
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Monikieliset asetukset",
        affirm: "vahvista",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Loading", // Loading
        finishedText: "none", //none
        loadingText: "Loading", // Loading
    },
};
export default obj;
