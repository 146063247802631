// 英语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "I want a loan",
        navTitlePlaceholder: "Results within 15 seconds",
        applicationProcedure: "Application Process",
        customerInform: "Authorized store information",
        investigate: "instant review",
        submitDocument: "Shop Platform Signing Agreement",
        transfer: "Make money to the store",
        withdraw: "Balance increase",
        attention: "* Note: Please be sure to apply after confirming the loan conditions",
        supportingDocuments: "Available supporting documents",
        notificationTimeNav: "Audit Result Notification",
        timeItem1: "Weekdays",
        timeItem1Title: "Monday and Sunday all day",
        timeItem2: "The third Sunday of every month",
        timeItem2Title: "00:00 to 00:20 system maintenance",
        timeNotification1: "The audit result will be sent to the store's login mailbox, please check it in time.",
        timeNotification2: "※The approved amount will be paid directly to your store.",
        timeNotification3: "The approval amount is generated according to the store's latitude and may not meet your expectations.",
        bMConditionsNav: "Loan Conditions", // Loan Conditions Title
        bMCContentNav: "Loan Conditions",
        bMCContent1: "Under the age of 70 and have a stable income (some income is also acceptable).",
        bMCContent2: "At the age of 71 during the transaction, we will stop lending.",
        bMCHintNav: "Note",
        bMCHint1: "Please check the loan conditions carefully.",
        bMCHint2: "The loan business signed by the company is handled by the Consultation and Dispute Resolution Center of the Loan Industry Association.",
        bMCHint3: "The relevant regulations are mentioned above.",
        selectMethodsNav: "How to Loan with 0 Interest",
        sMInformNav: "Condition",
        sMInform1: 'The store has been operating for 3 years!',
        sMInform2: "The store has no breach of contract within 3 years.",
        sMInform3: 'There is no purchase within the store platform for more than 3 days. ',
        EnhancedNav: "Safe Operation",
        ESInformNav: "Watch out for malicious tactics",
        ESInform1: "※After making the payment, I was told to hand over the card and password.",
        ESInform2: "The loan was successfully mailed to the home.",
        ESInform3: "These are common malicious scams. The company has no relationship with these suppliers.",
        affirmBtn: "Apply now",
        dialogTitle: "Authorize your store information",
        dialogShopImages: "Shop Images",
        dialogMail: "Login Email",
        dialogAddress: "Store Name",
        dialogPhone: "Certificate front",
        dialogPhone2: "Reverse side of document",
        dialogBtn: "Confirm, apply for loan",
        message: "Your store information has not been obtained!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Loan Amount",
            value: "$50-$50,000",
        },
        {
            label: "Loan Term",
            value: "Up to 3 months, varies according to loan amount and store size",
        },
        {
            label: "Borrowing rate (daily rate)",
            value: "0.01%～10.0%, the loan interest rate varies according to the loan amount and store dimension",
        },
        {
            label: "Use object",
            value: "Mall seller",
        },
        {
            label: "Repayment method",
            value: "Contact customer service to communicate",
        },
        {
            label: "Guarantor",
            value: "Shop Platform",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Result feedback",
        hint: "The application has been submitted, the review result will be sent to you later by email",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Version upgrade, please confirm?", //Version upgrade, please confirm?
        loadingTitle: "Downloading...", // Downloading...
        systemUpgrade: "system upgrade", // system upgrade
        upgradeImmediately: "Confirm", // Confirm
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Multilingual Settings",
        affirm: "confirm",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Loading", // Loading
        finishedText: "none", //none
        loadingText: "Loading", // Loading
    },
};
export default obj;
