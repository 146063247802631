// 葡萄牙语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Quero um empréstimo",
        navTitlePlaceholder: "Resultados em 15 segundos",
        applicationProcedure: "Processo de Candidatura",
        customerInform: "Informações da loja autorizada",
        investigate: "revisão instantânea",
        submitDocument: "Contrato de assinatura da plataforma da loja",
        transfer: "Ganhar dinheiro para a loja",
        withdraw: "Aumento do saldo",
        attention: "* Nota: Por favor, certifique-se de aplicar após confirmar as condições do empréstimo",
        supportingDocuments: "Documentos comprovativos disponíveis",
        notificationTimeNav: "Notificação de resultado de auditoria",
        timeItem1: "Dias da semana",
        timeItem1Title: "Segunda e Domingo o dia todo",
        timeItem2: "O terceiro domingo de cada mês",
        timeItem2Title: "manutenção do sistema das 00:00 às 00:20",
        timeNotification1: "O resultado da auditoria será enviado para a caixa de entrada de login da loja, verifique a tempo.",
        timeNotification2: "※O valor aprovado será pago diretamente na sua loja.",
        timeNotification3: "O valor da aprovação é gerado de acordo com a latitude da loja e pode não atender às suas expectativas.",
        bMConditionsNav: "Loan Conditions", // Título das condições do empréstimo
        bMCContentNav: "Condições de empréstimo",
        bMCContent1: "Menos de 70 anos e renda estável (alguma renda também é aceitável).",
        bMCContent2: "Aos 71 anos durante a transação, deixaremos de emprestar.",
        bMCHintNav: "Nota",
        bMCHint1: "Por favor, verifique cuidadosamente as condições do empréstimo.",
        bMCHint2: "O negócio de crédito firmado pela empresa é tratado pelo Centro de Consulta e Resolução de Controvérsias da Associação da Indústria de Crédito.",
        bMCHint3: "Os regulamentos relevantes são mencionados acima.",
        selectMethodsNav: "Como Empréstimo com 0 Juros",
        sMInformNav: "Condição",
        sMInform1: 'A loja está em funcionamento há 3 anos!',
        sMInform2: "A loja não tem quebra de contrato em 3 anos.",
        sMInform3: 'Não há compra na plataforma da loja por mais de 3 dias. ',
        EnhancedNav: "Operação Segura",
        ESInformNav: "Cuidado com táticas maliciosas",
        ESInform1: "※Após efetuar o pagamento, me disseram para entregar o cartão e a senha.",
        ESInform2: "O empréstimo foi enviado com sucesso para a casa.",
        ESInform3: "São golpes maliciosos comuns. A empresa não tem relação com esses fornecedores.",
        affirmBtn: "Solicite agora",
        dialogTitle: "Autorize as informações da sua loja",
        dialogShopImages: "Loja de imagens",
        dialogMail: "E-mail de login",
        dialogAddress: "Nome da Loja",
        dialogPhone: "Frente do certificado",
        dialogPhone2: "Verso do documento",
        dialogBtn: "Confirmar, solicitar empréstimo",
        message: 'As informações da sua loja não foram obtidas!'
    },
    // 贷款申请页表格语言
    tableLanguage: [{
        label: "Valor do empréstimo",
        value: "$ 50-$ 50.000",
    },
        {
            label: "Prazo do Empréstimo",
            value: "Até 3 meses, varia de acordo com o valor do empréstimo e tamanho da loja",
        },
        {
            label: "Taxa de empréstimo (taxa diária)",
            value: "0,01%~10,0%, a taxa de juros do empréstimo varia de acordo com o valor do empréstimo e dimensão da loja",
        },
        {
            label: "Usar objeto",
            value: "Vendedor de shopping",
        },
        {
            label: "Método de reembolso",
            value: "Entre em contato com o atendimento ao cliente para se comunicar",
        },
        {
            label: "Fiador",
            value: "Plataforma da Loja",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Retorno do resultado",
        hint: "A inscrição foi enviada, o resultado da revisão será enviado para você posteriormente por e-mail",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Atualização da versão, por favor confirme?", //Atualização da versão, por favor confirme?
        loadingTitle: "Baixando...", // Baixando...
        systemUpgrade: "atualização do sistema", // atualização do sistema
        upgradeImmediately: "Confirmar", // Confirmar
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Configurações multilíngues",
        affirm: "confirmar",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Carregando", // Carregando
        finishedText: "nenhum", // nenhum
        loadingText: "Carregando", // Carregando
    },
};
export default obj;