// 日语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "ローンが欲しい",
        navTitlePlaceholder: "15 秒以内に結果",
        applicationProcedure: "申請プロセス",
        customerInform: "正規販売店情報",
        investigate: '即時レビュー',
        submitDocument: "ショップ プラットフォーム署名契約",
        transfer: "店にお金を稼ぐ",
        withdraw: '残高増加',
        attention: '※注意：必ず貸出条件をご確認の上、お申し込みください',
        supportingDocuments: "利用可能なサポート ドキュメント",
        notificationTimeNav: "監査結果通知",
        timeItem1: "平日",
        timeItem1Title: "月曜日と日曜日の終日",
        timeItem2: "毎月第 3 日曜日",
        timeItem2Title: "00:00～00:20 システムメンテナンス",
        timeNotification1: "監査結果はストアのログイン メールボックスに送信されます。時間内に確認してください。",
        timeNotification2: "※承認された金額は直接店舗に支払われます。",
        timeNotification3: "承認額は店舗の緯度に応じて生成されるため、ご期待に添えない場合があります。",
        bMConditionsNav: "融資条件", // 融資条件のタイトル
        bMCContentNav: "貸出条件",
        bMCContent1: "70歳未満で安定した収入のある方(多少の収入も可)",
        bMCContent2: "取引中の71歳で、貸し出しを停止します。",
        bMCHintNav: "メモ",
        bMCHint1: "貸出条件をよくご確認ください。",
        bMCHint2: "会社が締結した融資業務は、融資業協会の相談および紛争解決センターによって処理されます。",
        bMCHint3: "関連する規制は上記のとおりです。",
        selectMethodsNav: "無利息でローンを組む方法",
        sMInformNav: "条件",
        sMInform1: 'この店は 3 年間営業しています!',
        sMInform2: "ストアは 3 年以内に契約違反がありません。",
        sMInform3: 'ストア プラットフォーム内で 3 日以上購入がありません。 ',
        EnhancedNav: "安全な操作",
        ESInformNav: "悪意のある戦術に注意してください",
        ESInform1: "※支払い後、カードとパスワードを渡すように言われました。",
        ESInform2: "ローンは正常に自宅に郵送されました。",
        ESInform3: "これらは一般的な悪意のある詐欺です。会社はこれらのサプライヤーとは何の関係もありません。",
        affirmBtn: "今すぐ申し込む",
        dialogTitle: "ストア情報を承認する",
        dialogShopImages: "ショップ画像",
        dialogMail: "ログインメール",
        dialogAddress: "店舗名",
        dialogPhone: "証明書フロント",
        dialogPhone2: "ドキュメントの裏面",
        dialogBtn: "確認、ローンを申し込む",
        message: 'ストア情報が取得されていません。',
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            // 第一行
            label: "ローン金額",
            value: "$50-$50,000",
        },
        {
            // 第二行
            label: "ローン期間",
            value: "最長3ヶ月、融資額や店舗規模により異なります。",
        },
        {
            // 第二行
            label: "借入金利（日利）",
            value: "0.01%～10.0%、貸出金利は貸出金額・店舗規模により変動",
        },
        {
            // 第三行
            label: "ユーザーの対象者",
            value: "カスタマーサービスに連絡して連絡してください",
        },
        {
            // 第四行
            label: "返済",
            value: "店舗プラットフォームの控除と返済",
        },
        {
            // 第五行
            label: "保証人",
            value: "店舗プラットフォーム",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "結果のフィードバック",
        hint: '申請が送信されました。審査結果は後でメールで送信されます'
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "バージョンアップ、確認しますか?", //バージョンアップ、確認しますか?
        loadingTitle: "ダウンロード中...", // ダウンロード中...
        systemUpgrade: "システムのアップグレード", // システムのアップグレード
        upgradeImmediately: "Confirm", // 確認
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "多言語設定",
        affirm: '確認する'
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "読み込み中", // 読み込み中
        finishedText: "なし", //なし
        loadingText: "読み込み中", // 読み込み中
    },
};
export default obj;
