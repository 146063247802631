import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import 'lib-flexible/flexible'
import '../src/utils/vant'
import './utils/appback.js'
import './utils/version'
import global_msg from './components/component/js/global'
import VueClipboard from 'vue-clipboard2' //引入插件
Vue.use(VueClipboard) //安装插件
Vue.prototype.$global_msg = global_msg;
Vue.prototype.$JumpShops = global_msg.$JumpShops;
Vue.config.productionTip = false
let time = 0
export default timeDate => {
  setTimeout(({
    time: 1
  }), 6000)
  return time
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')