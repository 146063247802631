// 韩语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "나는 대출을 원한다",
        navTitlePlaceholder: "15 초 이내에 결과",
        applicationProcedure: "응용 프로그램 프로세스",
        customerInform: "공인 상점 정보",
        investigate: "인스턴트 리뷰",
        submitDocument: "상점 플랫폼 서명 계약",
        transfer: "상점에 돈을 버는 것",
        withdraw: "밸런스 증가",
        attention: "* 참고 : 대출 조건을 확인한 후 신청하십시오",
        supportingDocuments: "사용 가능한 지원 서류",
        notificationTimeNav: "감사 결과 알림",
        timeItem1: "주중",
        timeItem1Title: "월요일과 일요일 하루 종일",
        timeItem2: "매월 세 번째 일요일",
        timeItem2Title: "00:00 ~ 00:20 시스템 유지 보수",
        timeNotification1: "감사 결과는 상점의 로그인 사서함으로 전송됩니다.",
        timeNotification2: "※ 승인 된 금액은 상점에 직접 지불됩니다.",
        timeNotification3: "승인 금액은 상점의 위도에 따라 생성되며 귀하의 기대를 충족시키지 못할 수 있습니다.",
        bMConditionsNav: "대출 조건", // 대출 조건 제목
        bMCContentNav: "대출 조건",
        bMCContent1: "70 세 미만의 안정적인 소득 (일부 소득도 허용됩니다).",
        bMCContent2: "거래 중에 71 세의 나이에 우리는 대출을 중단 할 것입니다.",
        bMCHintNav: "참고",
        bMCHint1: "대출 조건을주의 깊게 확인하십시오.",
        bMCHint2: "회사가 서명 한 대출 사업은 대출 산업 협회의 상담 및 분쟁 해결 센터에 의해 처리됩니다.",
        bMCHint3: "관련 규정은 위에서 언급되어 있습니다.",
        selectMethodsNav: "이자로 대출하는 방법",
        sMInformNav: "조건",
        sMInform1: '매장은 3 년 동안 운영되었습니다!',
        sMInform2: "매장은 3 년 이내에 계약을 위반하지 않습니다.",
        sMInform3: '매장 플랫폼 내에서 3 일 이상 구매할 수 없습니다. ',
        EnhancedNav: "안전한 작동",
        ESInformNav: "악의적 인 전술을 조심하십시오",
        ESInform1: "1 결제 후 카드와 비밀번호를 넘겨 주라는 지시를 받았습니다.",
        ESInform2: "대출은 집으로 성공적으로 우송되었습니다.",
        ESInform3: "이것은 일반적인 악의적 인 사기입니다. 회사는 이러한 공급 업체와 관계가 없습니다.",
        affirmBtn: "지금 신청",
        dialogTitle: "상점 정보 승인",
        dialogShopImages: "쇼핑 이미지",
        dialogMail: "로그인 이메일",
        dialogAddress: "저장 이름",
        dialogPhone: "인증서 전면",
        dialogPhone2: "문서의 반대쪽",
        dialogBtn: "확인, 대출 신청",
        message: "귀하의 상점 정보는 얻지 못했습니다!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "대출 금액",
            value: "$ 50- $ 50,000",
        },
        {
            label: "대출 기간",
            value: "최대 3개월, 대출금액 및 점포규모에 따라 다름",
        },
        {
            label: "차입률 (일일 요금)",
            value: "0.01%～ 10.0%, 대출 이자율은 대출 금액 및 저장 차원에 따라 다릅니다.",
        },
        {
            label: "객체를 사용하십시오",
            value: "쇼핑몰 판매자",
        },
        {
            label: "상환 방법",
            value: "의사 소통을 위해 고객 서비스에 문의하십시오.",
        },
        {
            label: "보증인",
            value: "상점 플랫폼",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "결과 피드백",
        hint: "신청서가 제출되었으며 검토 결과는 나중에 이메일로 귀하에게 전송됩니다.",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "버전 업그레이드, 확인하십시오?", // 버전 업그레이드, 확인 해주세요.
        loadingTitle: "다운로드 ...", // 다운로드 ...
        systemUpgrade: "시스템 업그레이드", // 시스템 업그레이드
        upgradeImmediately: "확인", // 확인
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "다국어 설정",
        affirm: "확인하다",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "로드", //로드
        finishedText: "없음", // 없음
        loadingText: "로드", //로드
    },
};
export default obj;
