// 简体中文

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "我要贷款",
        navTitlePlaceholder: "最快15秒内出结果",
        applicationProcedure: "申请流程",
        customerInform: "授权店铺信息",
        investigate: "即时审查",
        submitDocument: "店铺平台签署协议",
        transfer: "打款至店铺",
        withdraw: "余额增加",
        attention: "* 注：请务必确认贷款条件后申请",
        supportingDocuments: "可用的证明性文件",
        notificationTimeNav: "审核结果通知",
        timeItem1: "平日",
        timeItem1Title: "周一与周日全天",
        timeItem2: "每月第三个周日",
        timeItem2Title: "00:00至00:20系统维护",
        timeNotification1: "审核结果将发送至店铺登录邮箱，请及时查收。",
        timeNotification2: "※审批金额会直接打款至您的店铺。",
        timeNotification3: "审批金额根据店铺纬度生成，可能会不符合您的期望。",
        bMConditionsNav: "贷款条件", // 贷款条件标题
        bMCContentNav: "贷款条件",
        bMCContent1: "年龄70岁以下且有稳定收入(部分有收入也可以)。",
        bMCContent2: "在交易期间满71岁时,我们将停止贷款。",
        bMCHintNav: "注",
        bMCHint1: "请仔细查阅借款条件。",
        bMCHint2: "本公司签订的贷款业务，由贷款业协会咨询纠纷解决中心处理。",
        bMCHint3: "上文提及有关规定。",
        selectMethodsNav: "如何0利息贷款",
        sMInformNav: "条件",
        sMInform1: '店铺运营时间达到3年!',
        sMInform2: "店铺3年内无违约行为。",
        sMInform3: '店铺平台内无超过3日采购。',
        EnhancedNav: "安全操作",
        ESInformNav: "注意恶意手法",
        ESInform1: "※打款后，被告知需交出卡和密码。",
        ESInform2: "贷款成功邮寄资料到达家中。",
        ESInform3: "这些都是常见的恶意欺诈手法。本公司与这些供应商没有任何关系。",
        affirmBtn: "立即申请",
        dialogTitle: "授权您的店铺信息",
        dialogShopImages: "店铺图像",
        dialogMail: "登录邮箱",
        dialogAddress: "店铺名称",
        dialogPhone: "证件正面",
        dialogPhone2: "证件反面",
        dialogBtn: "确认，申请贷款",
        message: "未获取到您的店铺信息！"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            // 第一行
            label: "贷款额度",
            value: "50美元-50000美元",
        },
        {
            // 第二行
            label: "贷款期限",
            value: "最长3个月，根据贷款金额及店铺维度而变动",
        },
        {
            // 第二行
            label: "借款利率 (日利率）",
            value: "0.01%～10.0%，贷款利率因贷款金额及店铺维度而变动",
        },
        {
            // 第三行
            label: "使用对象",
            value: "商城卖家",
        },
        {
            // 第四行
            label: "还款方式",
            value: "店铺平台抵扣还款",
        },
        {
            // 第五行
            label: "担保方",
            value: "店铺平台",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "结果反馈",
        hint: "申请已提交，审核结果稍后我们 会以邮箱形式发送给您",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "版本升级，请确认？ ", //版本升级，请确认？
        loadingTitle: "正在下载...", // 正在下载...
        systemUpgrade: "系统升级", // 系统升级
        upgradeImmediately: "确认", // 确认
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "多语言设置",
        affirm: "确认",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "加载中", // 正在加载
        finishedText: "无", //无
        loadingText: "加载中", // 正在加载
    },
};
export default obj;
