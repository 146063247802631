// 西班牙语

let obj = {};
obj = {
    // 贷款页面
    "Loans/loans-index": {
        navTitle: "Quiero un préstamo",
        navTitlePlaceholder: "Resultados en 15 segundos",
        applicationProcedure: "Proceso de solicitud",
        customerInform: "Información de la tienda autorizada",
        investigate: "revisión instantánea",
        submitDocument: "Acuerdo de firma de plataforma de tienda",
        transfer: "Gana dinero en la tienda",
        withdraw: "Aumento de saldo",
        attention: "* Nota: asegúrese de aplicar después de confirmar las condiciones del préstamo",
        supportingDocuments: "Documentos de respaldo disponibles",
        notificationTimeNav: "Notificación de resultado de auditoría",
        timeItem1: "Días laborables",
        timeItem1Title: "Lunes y domingo todo el día",
        timeItem2: "El tercer domingo de cada mes",
        timeItem2Title: "00:00 a 00:20 mantenimiento del sistema",
        timeNotification1: "El resultado de la auditoría se enviará al buzón de inicio de sesión de la tienda, verifíquelo a tiempo",
        timeNotification2: "※El monto aprobado se pagará directamente en tu tienda.",
        timeNotification3: "El monto de la aprobación se genera de acuerdo con la latitud de la tienda y es posible que no cumpla con sus expectativas",
        bMConditionsNav: "Condiciones del préstamo", // Título de las condiciones del préstamo
        bMCContentNav: "Condiciones de préstamo",
        bMCContent1: "Menos de 70 años y tener un ingreso estable (algunos ingresos también son aceptables).",
        bMCContent2: "A la edad de 71 años durante la transacción, dejaremos de prestar",
        bMCHintNav: "Nota",
        bMCHint1: "Por favor revise las condiciones del préstamo cuidadosamente.",
        bMCHint2: "El negocio de préstamos firmado por la empresa es manejado por el Centro de Consulta y Resolución de Disputas de la Asociación de la Industria de Préstamos",
        bMCHint3: "Las regulaciones relevantes se mencionan arriba.",
        selectMethodsNav: "Cómo prestar con 0 interés",
        sMInformNav: "Condición",
        sMInform1: '¡La tienda ha estado funcionando durante 3 años!',
        sMInform2: "La tienda no tiene incumplimiento de contrato en 3 años.",
        sMInform3: 'No hay compra dentro de la plataforma de la tienda por más de 3 días. ',
        EnhancedNav: "Operación segura",
        ESInformNav: "Cuidado con las tácticas maliciosas",
        ESInform1: "※Después de realizar el pago, me dijeron que entregara la tarjeta y la contraseña.",
        ESInform2: "El préstamo se envió con éxito a la casa.",
        ESInform3: "Estas son estafas maliciosas comunes. La empresa no tiene relación con estos proveedores",
        affirmBtn: "Aplicar ahora",
        dialogTitle: "Autoriza la información de tu tienda",
        dialogShopImages: "Comprar imágenes",
        dialogMail: "Correo electrónico de inicio de sesión",
        dialogAddress: "Nombre de la tienda",
        dialogPhone: "Anverso del certificado",
        dialogPhone2: "Reverso del documento",
        dialogBtn: "Confirmar, solicitar préstamo",
        message: "¡No se ha obtenido la información de su tienda!"
    },
    // 贷款申请页表格语言
    tableLanguage: [
        {
            label: "Monto del préstamo",
            value: "$50-$50,000",
        },
        {
            label: "Plazo del préstamo",
            value: "Hasta 3 meses, varía según el monto del préstamo y el tamaño de la tienda",
        },
        {
            label: "Tasa de préstamo (tasa diaria)",
            value: "0.01%～10.0%, la tasa de interés del préstamo varía según el monto del préstamo y la dimensión de la tienda",
        },
        {
            label: "Usar objeto",
            value: "vendedor de centro comercial",
        },
        {
            label: "Método de pago",
            value: "Póngase en contacto con el servicio de atención al cliente para comunicarse",
        },
        {
            label: "Garante",
            value: "Tienda Plataforma",
        },
    ],
    // 结果反馈页面
    "Loans/feedback-index": {
        navTitle: "Comentarios de resultados",
        hint: "La solicitud ha sido enviada, el resultado de la revisión se le enviará más tarde por correo electrónico",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "Actualización de versión, ¿confirmar?", //¿Actualización de versión, por favor confirmar?
        loadingTitle: "Descargando...", // Descargando...
        systemUpgrade: "actualización del sistema", // actualización del sistema
        upgradeImmediately: "Confirmar", // Confirmar
    },
    // 设置语言
    languageShiftLanguage: {
        navTitle: "Configuración multilingüe",
        affirm: "confirmar",
    },
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁體中文
        // '简体中文'  // 简体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', //土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'Nederlands', // 荷兰语
    ],
    messageLanguage: {
        loadingTitle: "Cargando", // Cargando
        finishedText: "ninguno", //ninguno
        loadingText: "Cargando", // Cargando
    },
};
export default obj;
